import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | SwiftWheels
			</title>
			<meta name={"description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:title"} content={"Головна | SwiftWheels"} />
			<meta property={"og:description"} content={"Ваша подорож починається тут"} />
			<meta property={"og:image"} content={"https://glowshub.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glowshub.com/img/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glowshub.com/img/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glowshub.com/img/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glowshub.com/img/images.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="0px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="60px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					SwiftWheels
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--dark"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Ласкаво просимо до SwiftWheels Rentals, де ваша подорож починається з простоти та зручності. Незалежно від того, чи ви досліджуєте нові напрямки, чи подорожуєте у справах, ми тут, щоб зробити ваш досвід оренди автомобіля безпроблемним і приємним. Наша дружня команда прагне, щоб ви залишилися задоволеними, знаючи, що зробили ідеальний вибір для своєї подорожі.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="transparent"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-light"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						border-width="1px"
						border-style="solid"
						border-color="--color-dark"
					>
						Звʼязатися з нами
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://glowshub.com/img/1.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
					Чому варто обрати SwiftWheels?
					</Text>
					<Image
						src="https://glowshub.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Конкурентоспроможні ціни: 
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Ми пропонуємо найкращі ціни на ринку, гарантуючи, що ви отримуєте велику вигоду кожного разу, коли орендуєте у нас.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Якісні автомобілі: 
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Кожен автомобіль в нашому автопарку ретельно обслуговується і проходить суворі перевірки, щоб забезпечити вашу безпеку і комфорт.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Винятковий сервіс: 
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Наша команда прагне допомогти вам знайти правильний автомобіль, який відповідає вашим потребам, з теплотою та доброзичливістю, на яку ви заслуговуєте.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
						Гнучкість: 
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
						Ми пропонуємо гнучкі варіанти бронювання відповідно до вашого графіку, що робить оренду з нами легкою та безтурботною.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 80px" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Подорожуйте щасливо зі SwiftWheels
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Забронюйте свій наступний прокат у нас і відчуйте різницю. У SwiftWheels ми не просто надаємо автомобілі напрокат - ми надаємо ключі до вашої наступної пригоди.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contact"
				type="link"
				text-decoration-line="initial"
			>
				Зв'яжіться з нами
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});